import * as firebase from "firebase/app";
import { getAuth } from "firebase/auth";
import { getDatabase } from "firebase/database";
import { getFirestore } from "firebase/firestore"

const firebaseConfig = {
    apiKey: "AIzaSyBx6ZziXAbedP5CDcI2YD99nyZBcZrqDL4",
    authDomain: "zodiaclmsapp.firebaseapp.com",
    databaseURL: "https://zodiaclmsapp-default-rtdb.asia-southeast1.firebasedatabase.app",
    projectId: "zodiaclmsapp",
    storageBucket: "zodiaclmsapp.appspot.com",
    messagingSenderId: "815498094527",
    appId: "1:815498094527:web:3234aa5105b2a050c2e30a",
    measurementId: "G-G7N4XB7V1H"
  };

const app = firebase.initializeApp(firebaseConfig);
const firebaseAuth = getAuth(app);
const fireStore = getFirestore(app);
const db = getDatabase(app);
const firebaseApp = app;

const getFirebaseApp = () => {
    return app;
}

export { firebaseAuth, firebase, fireStore, firebaseApp, db, getFirebaseApp };

