import { Spinner } from "react-bootstrap"


const EmptySpace = ({ message = "No Data Found." }) => {

    return (
        <div style={{ height: "15rem" }} className=" d-flex justify-content-center align-items-center w-100" >
            <div>{message}</div>
        </div>
    )
}

export default EmptySpace;