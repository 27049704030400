import useLocalStorage from "use-local-storage";

export function useToken() {
    return useLocalStorage("token")
}

export function useAuth() {
    return useLocalStorage("user")
}

export function useQueryParams() {
    return useLocalStorage("queryParams")
}

export function useCourseId() {
    return useLocalStorage("courseid")
}

export function useCurrentTime() {
    const gettime = localStorage.getItem("adgjl")
    // const getIdAttempt = localStorage.getItem("attempt")
    return (gettime)
}

export function useCurrentAttemptId () {
    return useLocalStorage("attempt_ID")
}

export function useCurrentAttemptTime () {
    return useLocalStorage("attempt_ID_time")
}