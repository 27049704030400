

const Avatar = ({ url }) => {

    return(
        <div>
             <img className='avatar' src={url || '/assets/avatar.png' }/>
        </div>
    )
}

export default Avatar;