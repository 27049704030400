import axios from "axios";
import { useToken } from "../hooks/localStorage/localStorage";

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
});

axiosInstance.interceptors.request.use(
  function (config) {
    // Do something before request is sent
   const token = localStorage.getItem("token")

  if(token){
    const tokenPayload =  JSON.parse(`{ "token": ${token} }`)
    // console.log(tokenPayload);
    config.headers.Authorization =
    "Bearer " + tokenPayload?.token;
  }
   config.headers["web"] = "web" ;

    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

export default axiosInstance;
