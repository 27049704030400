import "bootstrap/dist/css/bootstrap.min.css";
import CustomRouter from "./router/router";
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { useEffect } from "react";
import {  firebaseApp, getFirebaseApp } from "./services/firebase";


const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      retry: false,
      staleTime: 60000 * 2,
      cacheTime: 60000 * 5
    },
  },
})

function App() {

  useEffect(()=>{
    getFirebaseApp()
  },[])

  return (
    <QueryClientProvider client={queryClient}>
      <CustomRouter />
    </QueryClientProvider>
  );
}

export default App;