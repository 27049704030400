import { Spinner } from "react-bootstrap"


const PageLoader = ({ className }) => {

    return (
        <div style={{ height: "30rem" }} className={` d-flex justify-content-center align-items-center w-100 ${className}`} >
            <Spinner animation="border" role="status" color="primary" >
                <span className="visually-hidden">Loading...</span>
            </Spinner>
        </div>
    )
}

export default PageLoader;