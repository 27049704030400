import Header from "./../components/Header/Header";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import React, { lazy } from "react";
import LOCATIONS from "./locations";
import ScreenWrapper from "../components/ScreenWrapper/ScreenWrapper";
import { useToken } from "../hooks/localStorage/localStorage";

//Public Screens
const Login = lazy(() => import("../pages/Authentication/login/login"))
// const RegisterScreen = lazy(()=> import("../pages/Authentication/register/register"))
const ProfileScreen = lazy(()=> import("../pages/Authentication/profile/profile"))
const AffairsByMonth = lazy(() => import("./../pages/AffairsByMonth"))
const AffairsPage = lazy(() => import("./../pages/Affairs"))
const AffairsVideo = lazy(() => import("./../pages/AffairsVideo/AffairsVideo"))
const Content = lazy(() => import("./../pages/Examcategory/Content"))
const Progress = lazy(() => import("../pages/Examprogress/Progress"))
const Materials = lazy(() => import("./../pages/Exammaterials/Materials"))
const Mocktest = lazy(() => import("./../pages/Mockexam/Mocktest"))
const LiveVideo = lazy(()=> import("./../pages/LiveVideo"))
const PDFView = lazy(()=> import("./../pages/PDFView/pdfView"))
const Instructions = lazy(() => import("../pages/instructions/Instructions"))
const Exampage = lazy(() => import("./../pages/Mockexam/Exampage"))
const Examend = lazy(() => import("./../pages/Mockexam/Examend"))
const RecordedClass = lazy(() => import("../pages/RecordedClass"))
const RecordedClassVideo = lazy(() => import("../pages/RecordedClassVideo/RecordedClassVideo"))
const Examhistory = lazy(() => import("../pages/Examhistory/Examhistory"))
const Result = lazy(() => import("../pages/ViewAnswers/Result"))
const Courserecord = lazy(() => import("../pages/Recordings/courserecord"))
const Examrecordedclass = lazy(() => import("../pages/RecordedClassVideo/ExamClassVideo"))
const Examresult = lazy(() => import("./../pages/Examresult/Examresult"))
const LiveClass = lazy(() => import("./../pages/LiveClass"))
const Search = lazy(() => import("./../pages/Search/Search"))
const Privacy = lazy(() => import("./../pages/Privacypolicy/index"))
const AccountDelete = lazy(() => import("./../pages/AccountDelete/index"))
const Terms = lazy(() => import("./../pages/TermsandConditions/index"))


const PUBLIC_ROUTES = [
    {
        location: LOCATIONS.login,
        component: Login
    },
    // {
    //     location: LOCATIONS.register,
    //     component: RegisterScreen
    // },
    {
        location: LOCATIONS.profile,
        component: ProfileScreen
    },
    {
        location: LOCATIONS.privacy,
        component: Privacy
    },
    {
        location: LOCATIONS.accountdelete,
        component: AccountDelete
    },
    {
        location: LOCATIONS.termsandconditions,
        component: Terms
    }
]



const PAGES_LIST = [
    {
        location: LOCATIONS.home,
        component: Content
    },
    {
        location: LOCATIONS.progress,
        component: Progress
    },
    {
        location: LOCATIONS.affairs,
        component: AffairsPage
    },
    {
        location: LOCATIONS.material,
        component: Materials
    },
    {
        location: LOCATIONS.mocktest,
        component: Mocktest
    },
    {
        location: LOCATIONS.instructions,
        component: Instructions
    },
    {
        location: LOCATIONS.exampage,
        component: Exampage
    },
    {
        location: LOCATIONS.affairsByMonth,
        component: AffairsByMonth
    },
    {
        location: LOCATIONS.affairsVideo,
        component: AffairsVideo
    },
    {
        location: LOCATIONS.examend,
        component: Examend
    },
    {
        location: LOCATIONS.liveVideo,
        component: LiveVideo
    },
    {
        location: LOCATIONS.pdf,
        component: PDFView
    },
    {
        location: LOCATIONS.affairsByMonth,
        component: AffairsByMonth
    },
    {
        location: LOCATIONS.affairsVideo,
        component: AffairsVideo
    },
    {
        location: LOCATIONS.recordedClass,
        component: RecordedClass
    },
    {
        location: LOCATIONS.recordedClassVideo,
        component: RecordedClassVideo
    },
    {
        location: LOCATIONS.examhistory,
        component: Examhistory
    },
    {
        location: LOCATIONS.completedTestResult,
        component: Result
    },
    {
        location: LOCATIONS.courserecord,
        component: Courserecord
    },
    {
        location: LOCATIONS.examrecord,
        component: Examrecordedclass
    },
    {
        location: LOCATIONS.examresult,
        component: Examresult
    },
    {
        location: LOCATIONS.liveClass,
        component: LiveClass
    },
    {
        location: LOCATIONS.search,
        component: Search
    },
    {
        location: LOCATIONS.privacy,
        component: Privacy
    },
    {
        location: LOCATIONS.accountdelete,
        component: AccountDelete
    },
    {
        location: LOCATIONS.termsandconditions,
        component: Terms
    }
]

const CustomRouter = () => {
    const [token] = useToken();


    const getRoutes = () => {
        if(token)return PAGES_LIST;
        return PUBLIC_ROUTES;
    }

    return (
        <>
            <BrowserRouter>
                <Header />
                <Routes>
                    {getRoutes()?.map?.((item) => {
                        return <Route key={item.location} lazy path={item.location} element={<ScreenWrapper Component={item.component} />} />;
                    })}
                    <Route path="*" element={<Navigate to ={token? LOCATIONS.home : LOCATIONS.login} />}/>
                </Routes>
            </BrowserRouter>
        </>
    )
}

export default CustomRouter;