import { Suspense, useEffect } from "react"
import { Container } from "react-bootstrap";
import PageLoader from "../loaders/pageLoader";
import { useLocation, useParams } from "react-router-dom";
import LOCATIONS from "../../router/locations";
import { formatLocations } from "../../utils/utils";
import { useQueryParams } from "../../hooks/localStorage/localStorage";


const ScreenWrapper = ({ children, Component }) => {
    const location = useLocation();
    const params = useParams();
    const hideContainer = formatLocations([LOCATIONS.instructions,LOCATIONS.examend,LOCATIONS.examresult,LOCATIONS.exampage], params)
    const [, setParams] = useQueryParams()

    useEffect(()=> {
        setParams(params)
    }, [params])

    const getComponent = () => {
        if (hideContainer.includes(location.pathname)) {
            return  <Component />;
        }

        return (
            <Container>
                <Component />
            </Container>
        )
        
    }

    return (
        <Suspense fallback={<PageLoader />} >
            {getComponent()}
        </Suspense>
    )
}

export default ScreenWrapper;