

export const openInNewTab = (url) => {
    const newWindow = window.open(url, '_blank');
    newWindow.focus();
  };

export function calculateTimeAgo(timestampStr) {
  const timestamp = new Date(timestampStr);
  const currentTime = new Date();
  const timeDifference = currentTime - timestamp;

  const seconds = Math.floor(timeDifference / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);

  if (seconds < 60) {
      return `${seconds} second${seconds !== 1 ? 's' : ''} ago`;
  } else if (minutes < 60) {
      return `${minutes} minute${minutes !== 1 ? 's' : ''} ago`;
  } else if (hours < 24) {
      return `${hours} hour${hours !== 1 ? 's' : ''} ago`;
  } else {
      return `${days} day${days !== 1 ? 's' : ''} ago`;
  }
}

export const formatLocations = (paths = [], params) => {
    try {
      return paths?.map?.((item) => {
        let pathName = item || "";
        Object.keys(params).forEach((key) => {
         if(params[key])pathName = pathName.replace(`:${key}`, params[key])
        })
        return pathName;
    });
    } catch (error) {
      return []
    }
}

// Encode a string to Base64
export function encodeToBase64(input) {
  try {
    return btoa(input);
  } catch (error) {
    console.error("Error encoding to Base64: " + error);
    return null;
  }
}

// Decode a Base64 string to its original form
export function decodeFromBase64(encodedString) {
  try {
    return atob(encodedString);
  } catch (error) {
    console.error("Error decoding from Base64: " + error);
    return null;
  }
}
