
const LOCATIONS = {
    affairs: "/affairs/:id",
    affairsByMonth: "/affairs/:id/:month",
    affairsVideo: "/affairsVideo/:id",
    recordedClass: "/recordedclass/:id",
    recordedClassVideo: "/recordedclassvideo/:id",
    progress: "/progress/:catId/:levelId",
    material: "/material/:id",
    mocktest: "/mocktest/:id",
    liveVideo: "/liveVideo/:id",
    pdf: "/pdf",
    instructions: "/instructions/:id",
    exampage: "/exampage/:id/:attemptId",
    examend: "/examend/:id/:attemptId",
    home: "/",
    login: "/login",
    register: "/register",
    examhistory: "/examhistory/:id",
    profile: "/profile",
    completedTestResult: "/completedTestResult/:attemptId",
    courserecord: "/recordedclass/:id/:syllabus_id",
    examrecord: "/examrecordedclass/:id",
    examresult: "/examresult/:id/:attemptId",
    liveClass: "/liveclass/:id",
    search: "/progress/search/:id",
    privacy:"/privacypolicy",
    accountdelete:"/delete-account",
    termsandconditions:"/termsandconditions"
}

export default LOCATIONS;