import { useQuery } from "@tanstack/react-query";
import Api from "../../core/config"


export default function useGetQuery({ dependency = [], endPoint, options, config }) {
    const { data, ...restProps } = useQuery({ queryKey: [endPoint, ...dependency], queryFn: () => Api.get(endPoint, config), staleTime: 0, cacheTime: 0,  ...options  } )
    return {
        responseData: data?.data || data,
        data,
        ...restProps
    }   
}