import Dropdown from 'react-bootstrap/Dropdown';
import { styled } from 'styled-components';
import LOCATIONS from '../../router/locations';
import { useAuth } from '../../hooks/localStorage/localStorage';
import Avatar from '../Avatar/avatar';

const UserMenu = ({ role }) => {
  const [user] = useAuth()

    const onLogOut = () => {
        localStorage.removeItem("token")
        window.location.href = LOCATIONS.login;
    }

    // console.log(user,"user");

    return (
        <UserMenuStyled>
            <Dropdown>
                <Dropdown.Toggle >
                    <div className='d-flex avatar-container' >
                        {/* <img className='avatar' src={user.imageUrl||'/assets/avatar.png'} /> */}
                        <Avatar url={user?.imageUrl} />
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                            <path d="M15.8333 6.66666L9.99996 12.5L4.16663 6.66666" stroke="#666666" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                    </div>
                </Dropdown.Toggle>

                <Dropdown.Menu>
                    <Dropdown.Item onClick={onLogOut} href={LOCATIONS.login}>Logout</Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
        </UserMenuStyled>
    );
};

export default UserMenu;

const UserMenuStyled = styled.div`
    margin-left: 16px;
    .dropdown-toggle {
        border-radius: 12px;
        border: 1px solid #666;
        background: #fff;
        height: 50px;
    }

    .avatar{
        width: 32px;
        height: 32px;
        border-radius: 12px;
        margin-right: 8px;
    }

    .dropdown-toggle::after{
        display: none !important;
    }

    .avatar-container{
        align-items: center;
     
    }
`