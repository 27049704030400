// export const getCardsEndpoint = () => `/category`;
// export const getLevelEndpoint = (categoryid) => `/category/${categoryid}`;
export const getProgressEndpoint = (categoryid,levelid) => `/category/level/${categoryid}/${levelid}`;
export const getMaterialsEndpoint = (courseId) => `material/${courseId}`;
export const getMocktestEndpoint = (courseId) => `exams/course/${courseId}`;
export const getInstructionsEndpoint = (examId) => `exams/${examId}`;
export const getExamidEndpoint = `examAttempt`;
export const getExamidEndpointv1 = 'v1/examAttempt';
export const course_search = "courses/search"
export const getAnsidEndpoint = `v1/examAnswer`;
export const endExam = `v1/endExam`;
export const currentaffairsList = `currentaffairs`;
export const currentaffairsVideo = `currentaffairsVideo`;
export const videoListCurrentAffairs = `videoListCurrentAffairs`;
export const recordedclass = `recordedclass`;
export const completeTest =`completeTest`;
export const categoryList = `categoryForWeb`;
export const getHistoryEndpoint = (courseId) => `examHistoryPage/${courseId}`;
export const completedTestResult = `completedTestResult`;
export const exam_recordedclass = `exam_recordedclass`;
export const course = 'course';
export const recordedclassById = 'recordedclassById';
export const live_video_details = "course_liveclass";
export const exam_recordedclass_course = `exam_recordedclass_course`;
export const examTimeCalculation = `examTimeCalculation`;
export const course_liveclass_course =`course_liveclass_course`;
export const exam_liveclass_course =`exam_liveclass_course`;
export const userdetails =`userdetails`;